import { emptyStorage, getStorageItem } from "services/storage";
import { ClockGreenSVG, CloseSVG, CrossSVG, LoadingSVG, TickSVG, TickWhiteSVG } from "../assets/icons";
import { RideStatusType } from "types/order";

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

export const rideStatusMap = (status: RideStatusType) => {
  return status === "RIDE_ENROUTE_PICKUP" || status === "RIDE_ARRIVED_PICKUP" || status === "RIDE_ASSIGNED"
    ? "Yet to start"
    : status === "RIDE_STARTED"
    ? "Ongoing"
    : status === "RIDE_ENDED"
    ? "Completed"
    : status === "RIDE_CANCELLED"
    ? "Cancelled"
    : String(status).toLowerCase().replace(/_/g, " ");
};

export const getStatusBgColor = (string: string) => {
  const type = string.toUpperCase();
  return type === "DELIVERED" || type === "ORDER-DELIVERED"
    ? "#129B4D"
    : type === "FOOD_PREPARATION" ||
      type === "READY" ||
      type === "OUTLET_ASSIGNED_FOR_DELIVERY" ||
      type === "RIDER_ASSIGNED" ||
      type === "RIDER_REACHED_SOURCE" ||
      type === "DISPATCH" ||
      type === "RIDER_REACHED_DESTINATION" ||
      type === "NOT APPLIED" ||
      type === "YET TO START"
    ? "#fcd4d2"
    : type === "UNDELIVERED" ||
      type === "CANCELLED_BY_CUSTOMER" ||
      type === "CANCELLED_BY_RIDER" ||
      type === "CANCELLED_BY_KITCHEN" ||
      type === "CLOSED" ||
      type === "CLOSE" ||
      type === "PAYMENT_FAILED" ||
      type === "ESCALATE" ||
      type === "CANCELLED"
    ? "rgba(225, 71, 45, 0.12)"
    : type === "CREATED" ||
      type === "PAYMENT_SUCCESS" ||
      type === "OPEN" ||
      type === "APPLIED" ||
      type === "RESOLVED" ||
      type === "ACCEPT" ||
      type === "ACCEPTED" ||
      type === "FULFILLED" ||
      type === "COMPLETED"
    ? "rgba(18, 155, 77, 0.12)"
    : type === "ADMIN" ||
      type === "PROCESSING" ||
      type === "REPLACEMENT" ||
      type === "ONGOING" ||
      type === "IN-PROGRESS" ||
      type === "SHIPPED"
    ? "#F0792E1F"
    : type === "SDK"
    ? "rgba(189, 59, 250, 0.1)"
    : type === "PWA"
    ? "rgba(85, 74, 240, 0.10)"
    : "";
};

export const getStatusTxtColor = (string: string) => {
  const type = string.toUpperCase();

  return type === "DELIVERED" || type === "ORDER-DELIVERED"
    ? "#FFFFFF"
    : type === "FOOD_PREPARATION" ||
      type === "READY" ||
      type === "OUTLET_ASSIGNED_FOR_DELIVERY" ||
      type === "RIDER_ASSIGNED" ||
      type === "RIDER_REACHED_SOURCE" ||
      type === "DISPATCH" ||
      type === "RIDER_REACHED_DESTINATION" ||
      type === "NOT APPLIED" ||
      type === "YET TO START"
    ? "#B42318"
    : type === "UNDELIVERED" ||
      type === "CANCELLED_BY_CUSTOMER" ||
      type === "CANCELLED_BY_RIDER" ||
      type === "CANCELLED_BY_KITCHEN" ||
      type === "CLOSED" ||
      type === "CLOSE" ||
      type === "PAYMENT_FAILED" ||
      type === "ESCALATE" ||
      type === "CANCELLED"
    ? "#E1472D"
    : type === "CREATED" ||
      type === "PAYMENT_SUCCESS" ||
      type === "OPEN" ||
      type === "APPLIED" ||
      type === "RESOLVED" ||
      type === "ACCEPT" ||
      type === "ACCEPTED" ||
      type === "FULFILLED" ||
      type === "COMPLETED"
    ? "#129B4D"
    : type === "ADMIN" ||
      type === "PROCESSING" ||
      type === "REPLACEMENT" ||
      type === "ONGOING" ||
      type === "IN-PROGRESS" ||
      type === "SHIPPED"
    ? "#F0792E"
    : type === "SDK"
    ? "#BD3BFA"
    : type === "PWA"
    ? "#554AF0"
    : "";
};

export const getStatusImgSrc = (type: string) =>
  type === "DELIVERED" || type === "ORDER-DELIVERED"
    ? TickWhiteSVG
    : type === "FOOD_PREPARATION" ||
      type === "READY" ||
      type === "OUTLET_ASSIGNED_FOR_DELIVERY" ||
      type === "RIDER_ASSIGNED" ||
      type === "RIDER_REACHED_SOURCE" ||
      type === "DISPATCH" ||
      type === "RIDER_REACHED_DESTINATION"
    ? LoadingSVG
    : type === "UNDELIVERED" ||
      type === "CANCELLED_BY_CUSTOMER" ||
      type === "CANCELLED_BY_RIDER" ||
      type === "CANCELLED_BY_KITCHEN" ||
      type === "PAYMENT_FAILED"
    ? CrossSVG
    : type === "CREATED" || type === "PAYMENT_SUCCESS" || type === "applicable"
    ? TickSVG
    : type === "open"
    ? ClockGreenSVG
    : type === "closed"
    ? CloseSVG
    : "";

export const getStatusImgAlt = (type: string) =>
  type === "completed" || type === "READY"
    ? "completed"
    : type === "delivering" || type === "prepairing"
    ? "in process"
    : type === "rejected" || type === "cancelled"
    ? "denied"
    : type === "accepted"
    ? "accepted"
    : type === "open"
    ? "open"
    : type === "closed"
    ? "closed"
    : type === "applicable"
    ? "applicable"
    : "";

export const updateLocationOutletFilter = (newOrdersFilters: any, val: any) => {
  const outletsArr: Array<string> = val.outlets.split(",");
  for (const outlet of outletsArr) {
    if (!newOrdersFilters["location-outlet"][val.city]) {
      newOrdersFilters["location-outlet"][val.city] = [outlet];
    } else if (newOrdersFilters["location-outlet"][val.city]?.indexOf(outlet) === -1) {
      newOrdersFilters["location-outlet"][val.city] = [...newOrdersFilters["location-outlet"][val.city], outlet];
    } else {
      newOrdersFilters["location-outlet"][val.city] = newOrdersFilters["location-outlet"][val.city]?.filter(
        (filterOutlet: string) => filterOutlet !== outlet,
      );
    }
  }
  return newOrdersFilters;
};

export const updateBrandsFilter = (newOrdersFilters: any, val: any) => {
  if (newOrdersFilters["brands"]?.indexOf(val.brand) === -1) {
    newOrdersFilters["brands"] = [...newOrdersFilters["brands"], val.brand];
  } else {
    newOrdersFilters["brands"] = newOrdersFilters["brands"].filter((filterBrand: string) => filterBrand !== val.brand);
  }
  return newOrdersFilters;
};

export const updateModesOfPaymentFilter = (newOrdersFilters: any, val: any) => {
  if (newOrdersFilters["modes-of-payment"].indexOf(val.mop) === -1) {
    newOrdersFilters["modes-of-payment"] = [...newOrdersFilters["modes-of-payment"], val.mop];
  } else {
    newOrdersFilters["modes-of-payment"] = newOrdersFilters["modes-of-payment"].filter(
      (filterMode: string) => filterMode !== val.mop,
    );
  }
  return newOrdersFilters;
};

export const updateIssuesStatusFilter = (newOrdersFilters: any, val: any, key: string) => {
  newOrdersFilters[key] = [val];
  return newOrdersFilters;
};

export const updateDeliveryModesFilter = (newOrdersFilters: any, val: any) => {
  if (newOrdersFilters["delivery-mode"].indexOf(val.dm) === -1) {
    newOrdersFilters["delivery-mode"] = [...newOrdersFilters["delivery-mode"], val.dm];
  } else {
    newOrdersFilters["delivery-mode"] = newOrdersFilters["delivery-mode"].filter(
      (filterMode: string) => filterMode !== val.dm,
    );
  }
  return newOrdersFilters;
};

export const isLocationOutletInFilter = (ordersFilters: any, val: any) => {
  const outletsArr = val.outlets.split(",");
  if (outletsArr.length > 1) {
    let allSelected = true;
    for (const outlet of outletsArr) {
      if (
        !ordersFilters["location-outlet"][val.city] ||
        ordersFilters["location-outlet"][val.city]?.indexOf(outlet) === -1
      ) {
        allSelected = false;
      }
    }
    return allSelected;
  } else {
    return !ordersFilters["location-outlet"][val.city] ||
      ordersFilters["location-outlet"][val.city]?.indexOf(outletsArr[0]) === -1
      ? false
      : true;
  }
};

export const resetOrdersFilter = (ordersFilters: any) => {
  ordersFilters["location-outlet"] = {};
  ordersFilters["brands"] = [];
  ordersFilters["date-time"] = {};
  ordersFilters["delivery-mode"] = [];
  ordersFilters["modes-of-payment"] = [];

  return ordersFilters;
};

export const getDisplayTime = (time: string) => {
  if (time === "") return "";
  const hours = new Date(time).getHours();
  let minutes = new Date(time).getMinutes().toString();
  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  if (hours > 12) {
    return `${hours - 12}:${minutes} PM`;
  } else return `${hours}:${minutes} AM`;
};

export const isOutletOpen = (openingTime: string, closingTime: string) => {
  if (openingTime === "" || closingTime === "") return "";
  const currentTime = new Date();
  if (
    new Date(currentTime).getHours() > new Date(openingTime).getHours() &&
    new Date(currentTime).getHours() > new Date(closingTime).getHours()
  ) {
    return "open";
  } else if (
    new Date(currentTime).getHours() > new Date(closingTime).getHours() ||
    new Date(currentTime).getHours() < new Date(openingTime).getHours()
  ) {
    return "closed";
  } else if (
    new Date(currentTime).getHours() === new Date(closingTime).getHours() &&
    new Date(currentTime).getMinutes() > new Date(closingTime).getMinutes()
  ) {
    return "closed";
  } else if (
    new Date(currentTime).getHours() === new Date(openingTime).getHours() &&
    new Date(currentTime).getMinutes() < new Date(openingTime).getMinutes()
  ) {
    return "closed";
  } else return "open";
};

export const getDisplayDate = (time: string) => {
  if (time === "") return "";
  const years = new Date(time).getFullYear();
  const month = new Date(time).getMonth();
  const date = new Date(time).getDate();

  return `${date} ${months[month]} ${years}`;
};

export const getTimeAgo = (time: string): string => {
  const seconds: number = Math.floor((new Date().getTime() - new Date(time).getTime()) / 1000);
  const interval: number = Math.floor(seconds / 60);

  if (seconds < 60) return `${seconds} seconds ago`;
  if (interval === 1) return "1 minute ago";
  if (interval < 60) return `${interval} minutes ago`;

  const hours: number = Math.floor(interval / 60);
  if (hours === 1) return "1 hour ago";
  if (hours < 24) return `${hours} hours ago`;

  const days: number = Math.floor(hours / 24);
  if (days === 1) return "yesterday";
  return `${days} days ago`;
};

export const getNextDayClosingTime = (startTime: Date, endTime: Date) => {
  const openingTime = new Date(startTime);
  let closingTime = new Date(endTime);
  if (
    closingTime.getHours() < openingTime.getHours() ||
    (closingTime.getHours() === openingTime.getHours() && closingTime.getMinutes() <= openingTime.getMinutes())
  ) {
    closingTime = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1,
      new Date(endTime).getHours(),
      new Date(endTime).getMinutes(),
      new Date(endTime).getSeconds(),
      new Date(endTime).getMilliseconds(),
    );
  }
  return closingTime;
};

export async function checkAuth(
  apiCallFn: Function,
  setText: Function,
  navigate: any,
  setApiInProgress: Function = () => {},
  timer?: number,
) {
  try {
    setApiInProgress(true);
    await apiCallFn();
  } catch (e: any) {
    setText(e?.response?.data?.error ?? "");
    if (e?.response?.status === 401) {
      emptyStorage();
      navigate("/login");
    }
  } finally {
    setTimeout(() => {
      setApiInProgress(false);
    }, timer ?? 0);
  }
}

export function formatCurrencyValue(value: string | number) {
  const integer = typeof value === "string" ? parseInt(value.split("₹")[1]) : value;
  const absValue = Math.abs(integer);
  if (absValue < 1000) {
    return integer.toString();
  } else if (absValue < 100000) {
    return (integer / 1000).toFixed(1) + "k";
  } else if (absValue < 10000000) {
    return (integer / 100000).toFixed(1) + "L";
  } else {
    return (integer / 10000000).toFixed(1) + "Cr";
  }
}

export function generateColor() {
  const hue = Math.floor(Math.random() * 360);
  return `hsl(${hue}, 60%, 70%)`;
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = date.toLocaleString("en-GB", options);
  return formattedDate.replace(",", " at");
};

export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function padDecimals(value: number, padding: number = 2) {
  return value !== 0 && Number.isInteger(value) ? value.toFixed(padding) : value;
}

export function currentRoleIsUser() {
  return getStorageItem("role") === "USER";
}
export const getDisplayStatus = (value: string, status?: string) => {
  switch (value) {
    case "Out-for-delivery":
    case "At-pickup":
    case "At-delivery":
      return "Out for Delivery";
    case "Order-delivered":
      return "Delivered";
    case "Delivery-failed":
      return "Delivery Failed";
    case "Pickup-failed":
      return "Pickup Failed";
    case "Cancelled":
      return "Cancelled";
    default:
      switch (status) {
        case "In-progress":
          return "Ready to Deliver";
        case "Accepted":
          return "Accepted";
        case "Created":
          return "Created";
        case "Initiated":
          return "Failed";
        default:
          return status || "Unknown Status";
      }
  }
};

export function generateUrlSearchParams(params: any, url: any) {
  Object.entries(params).reduce((params, [key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      const arrayParams: any = value.map(val => url.set(key, val));
      return params.concat(arrayParams);
    } else if (value && typeof value === "object" && Object.keys(value).length > 0) {
      const nestedParams: any = Object.entries(value).map(([nestedKey, nestedValue]) =>
        url.set(nestedKey, nestedValue),
      );
      return params.concat(nestedParams);
    } else {
      return params;
    }
  }, []);
}

export function generateSortUrlSearchParams(params: any, url: any) {
  Object.entries(params).reduce((params, [key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      // eslint-disable-next-line array-callback-return
      const arrayParams: any = value.map(val => {
        url.set("sortBy", key);
        url.set("sortOrder", val);
      });
      return params.concat(arrayParams);
    } else if (value && typeof value === "object" && Object.keys(value).length > 0) {
      const nestedParams: any = Object.entries(value).map(
        // eslint-disable-next-line array-callback-return
        ([nestedKey, nestedValue]) => {
          url.set("sortBy", nestedKey);
          url.set("sortorder", nestedValue);
        },
      );
      return params.concat(nestedParams);
    } else {
      return params;
    }
  }, []);
}
