import { useEffect, useState } from "react";
import { Input, TextInput, MultiSelect } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "components/Common/Button";
import "react-quill/dist/quill.snow.css";
import { useNotification } from "context";
import { useGetSectionListQuery } from "hooks/Admin/sectionAPI";
import ReactQuill from "react-quill";
import { useAddPageMutation, useUpdatePageMutation } from "hooks/Admin/pageAPI";
import { getPageDetails } from "services/apis/admin";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; // Import Drag and Drop components
import { DragIcon, SectionContainer, SectionName } from "./BasicDetails.types";

const BasicDetails = ({
  setAddPageModalVisible,
  setEditPageModal,
  editPageModal,
}: {
  setAddPageModalVisible: Function;
  setEditPageModal: Function;
  editPageModal: any;
}) => {
  const pageDetailsForm = useForm<any>({
    validateInputOnChange: true,
    initialValues: {
      title: "",
      slug: "",
      meta: { title: "", description: "" },
      sections: [],
      isActive: true,
    },

    validate: {
      name: (value) => {
        if (value?.length === 0) {
          return "Title is required";
        } else if (value?.length > 30) {
          return "Title must be 30 characters or less";
        }
        return null;
      },
    },
  });

  const { setText } = useNotification();

  const [updatePage] = useUpdatePageMutation();
  const [addPage] = useAddPageMutation();
  const [sectionItems, setSectionItems] = useState([]);
  const [selectedSections, setSelectedSections] = useState<any[]>([]); // State to store selected sections

  const { data: sectionList } = useGetSectionListQuery(
    {
      page: "1",
      size: "1000",
    },
    { skip: false }
  );

  const fetchPageDetails = async (id: any) => {
    const response = await getPageDetails(id);

    if (response.success) {
      const selectedSections = response?.data?.pages[0].sections.map(
        (ele: any, index: number) => ({
          id: ele?.section?._id,
          name: ele?.section?.name,
          position: index + 1,
        })
      );

      pageDetailsForm.setValues({
        title: response.data.pages[0].title,
        name: response.data.pages[0].meta.title,
        slug: response.data.pages[0].slug,
        description: response.data.pages[0].meta.description,
        sections: selectedSections,
        isActive: response?.data?.pages[0].isActive,
      });
      setSelectedSections(selectedSections);
    }
  };

  useEffect(() => {
    if (editPageModal.isOpen) {
      fetchPageDetails(editPageModal?.page?.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPageModal.isOpen]);

  useEffect(() => {
    if (sectionList) {
      setSectionItems(
        sectionList?.data?.sections?.map((section: any) => ({
          id: section._id,
          name: section.name,
        }))
      );
    }
  }, [sectionList]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
  
    const items = Array.from(selectedSections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    // Update positions
    const updatedItems = items.map((item, index) => ({
      ...item,
      position: index + 1,
    }));
  
    setSelectedSections(updatedItems);
    pageDetailsForm.setFieldValue("sections", updatedItems);
  };
  

  async function handleAddPage() {
    const section_ids = selectedSections.map((section: any, index: number) => ({
      section: section.id,
      position: index + 1,
      isVisible: true,
    }));

    const payload = {
      title: pageDetailsForm.values.title || undefined,
      slug: pageDetailsForm.values.slug,
      meta: {
        title: pageDetailsForm.values.name,
        description: pageDetailsForm.values.description,
      },
      sections: section_ids,
      isActive: pageDetailsForm.values.isActive,
    };

    const id = editPageModal?.page?._id;
    if (editPageModal.isOpen) {
      const response = await updatePage({ id, payload });
      if (response?.data?.success) {
        setEditPageModal({
          isOpen: false,
          page: "",
        });
        setText("Page Updated");
      }
    } else {
      const response = await addPage(payload);

      if (response.data.success) {
        setAddPageModalVisible(false);
        setText("Page Added");
      }
    }
  }

  return (
    <form
      className="w-650 pt-20 pb-20 pl-20 pr-20"
      onSubmit={pageDetailsForm.onSubmit(handleAddPage)}
    >
      <p className="txt-16 mb-20">
        <b>Page Details</b>
      </p>
      <div className="grid grid-cols-2 col-gap-40 row-gap-25 pb-10">
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="PAGE NAME"
          required
        >
          <TextInput
            placeholder="Enter Page Name"
            variant="filled"
            {...pageDetailsForm.getInputProps("name")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="SLUG"
          required
        >
          <TextInput
            placeholder="Enter Slug"
            variant="filled"
            {...pageDetailsForm.getInputProps("slug")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="TITLE"
          required
        >
          <TextInput
            placeholder="Enter Title"
            variant="filled"
            {...pageDetailsForm.getInputProps("title")}
          />
        </Input.Wrapper>

        <div className="gcol-start-1 gcol-end-3">
          <p className="clr-grey txtw-4 txt-12 txt-ucase">description</p>
          <ReactQuill
            theme="snow"
            {...pageDetailsForm.getInputProps("description")}
          />
          {pageDetailsForm.errors.description && (
            <p className="txt-12 clr-red mt-5">
              {pageDetailsForm.errors.description}
            </p>
          )}
        </div>
      </div>
      <MultiSelect
        data={sectionItems.map((section: any) => ({
          label: section.name,
          value: section.id,
        }))}
        label="SECTIONS"
        placeholder="Pick Sections"
        multiple
        value={selectedSections.map((section) => section.id)}
        onChange={(values: any[]) => {
          const selected = sectionItems.filter((section: any) =>
            values.includes(section.id)
          );

          // Preserve order and position
          const updatedSelectedSections = [...selectedSections];

          // Add new sections to the end
          selected.forEach((section) => {
            if (!selectedSections.find((s) => s.id  === (section as any)?.id)) {
              updatedSelectedSections.push({
                ...(section as any),
                position: updatedSelectedSections.length + 1,
              });
            }
          });

          // Remove sections not in the selected list
          const finalSelectedSections = updatedSelectedSections.filter((section) =>
            values.includes(section.id)
          );

          setSelectedSections(finalSelectedSections);
          pageDetailsForm.setFieldValue("sections", finalSelectedSections);
        }}
      />


      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="sections">
          {(provided: any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                paddingTop: "20px", // Add top padding here
              }}
            >
              {selectedSections.map((section: any, index: number) => (
                <Draggable
                  key={section.id}
                  draggableId={section.id}
                  index={index}
                >
                  {(provided: any) => (
                    <SectionContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <SectionName>{section.name}</SectionName>
                      <DragIcon>☰</DragIcon>
                    </SectionContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="flex justify-end bd-top pt-10">
        <Button
          type="filled-primary"
          text={`${editPageModal.isOpen ? "Save" : "Add"}`}
          loading={false}
          onClick={() => { }}
        />
      </div>
    </form>
  );
};

export default BasicDetails;
