import CustomTable from "components/CustomTable/CustomTable";
import DetailsCard from "components/DetailsCard/DetailsCard";
import { Divider, Text, Timeline } from "@mantine/core";
import { ReactComponent as CheckCircle } from "assets/icons/CheckCircle.svg";

import { ReactComponent as PhonePe } from "assets/icons/Phonepe.svg";
import { ReactComponent as Visa } from "assets/icons/Visa.svg";
import "pages/OrderDetails/OrderDetails.scss";
import { Fragment, useEffect, useState } from "react";
import { formatDate, getDisplayDate, getDisplayStatus, getDisplayTime } from "utils/helper";
import { ecommerceColumns } from "components/OrderDetails/Columns";
import SupportTable from "components/OrderDetails/SupportTable";
import { Header } from "components/Common/Header";
import {
  useGetOrderDetailsQuery,
  // useGetOrderTrackingDetailsQuery,
} from "hooks/Admin/orderAPI";
import { useParams } from "react-router-dom";
// import ImagePreview from "components/Common/ImagePreview/ImagePreview";

export default function ECommerceOrderDetails() {
  const params = useParams();
  const productId = params.id;
  const [productsListing, setProductsListing] = useState([]);
  const { data: orderDetails } = useGetOrderDetailsQuery(productId);
  // const { data: trackingDetails } = useGetOrderTrackingDetailsQuery(productId)

  const {
    refund,
    settlement,
    status,
    cart,
    parentOrder,
    customerOrderId,
    customerId,
    createdAt,
    updatedAt,
    fulfillment,
    supportTickets,
    returns,
  } = orderDetails?.data ?? {};
  let refundDetails = refund || null;

  if (refund) {
    if (Array.isArray(refund)) refundDetails = refund;
    else refundDetails = [refund];
  }

  const currentTrackingStatus = fulfillment?.status;

  const returnStatus = returns?.[0]?.status;

  const notrackingDetailsArr = [
    {
      status: "",
      active: false,
      text: "",
    },
    {
      status: "Created",
      active: currentTrackingStatus === "Pending" && status == "Created",
      text: "Order created",
    },
    {
      status: "Accepted",
      active: currentTrackingStatus === "Pending" && status == "Accepted",
      text: "Order accepted",
    },
    {
      status: status === "Pending" ? "Pending" : status === "Packed" || status === "Completed" ? "Packed" : "Pending",
      active: currentTrackingStatus === "Packed",
      text:
        status === "Pending"
          ? "Order is being prepared"
          : status === "Packed" || status === "Completed"
          ? "Order prepared"
          : "Order is being prepared",
    },
    {
      status: "Agent Assigned",
      active: currentTrackingStatus === "Agent-assigned",
      text: "Agent has been assigned for your order",
    },
    ...(currentTrackingStatus === "Pickup-failed"
      ? [
          {
            status: "Pickup Failed",
            active: true,
            text: "Pickup failed",
          },
        ]
      : []),
    {
      status: "Picked Up",
      active:
        currentTrackingStatus === "Order-picked-up" ||
        currentTrackingStatus === "In-transit" ||
        currentTrackingStatus === "Out-for-pickup" ||
        currentTrackingStatus === "At-destination-hub",
      text: "Order picked up by delivery partner",
    },
    {
      status: "Out for Delivery",
      active: currentTrackingStatus === "Out-for-delivery",
      text: "Out for delivery",
    },
    ...(currentTrackingStatus === "Delivery-failed"
      ? [
          {
            status: "Delivery Failed",
            active: true,
            text: "Delivery failed",
          },
        ]
      : []),
    {
      status: "Delivered",
      active: currentTrackingStatus === "Order-delivered",
      text: "Order delivered",
    },
  ];
  const fnbtrackingDetailsArr = [
    {
      status: "",
      active: false,
      text: "",
    },
    {
      status: "Created",
      active: currentTrackingStatus === "Pending" && status == "Created",
      text: "Order created",
    },
    {
      status: "Accepted",
      active: currentTrackingStatus === "Pending" && status == "Accepted",
      text: "Order accepted",
    },
    {
      status: status === "Pending" ? "Pending" : status === "Packed" || status === "Completed" ? "Packed" : "Pending",
      active: currentTrackingStatus === "Packed",
      text:
        status === "Pending"
          ? "Order is being prepared"
          : status === "Packed" || status === "Completed"
          ? "Order prepared"
          : "Order is being prepared",
    },
    {
      status: "Agent Assigned",
      active: currentTrackingStatus === "Agent-assigned",
      text: "Agent has been assigned for your order",
    },
    {
      status: "At-pickup",
      active: currentTrackingStatus === "At-pickup",
      text: "The agent has arrived at the pickup location",
    },
    ...(currentTrackingStatus === "Pickup-failed"
      ? [
          {
            status: "Pickup Failed",
            active: true,
            text: "Pickup failed",
          },
        ]
      : []),
    {
      status: "Picked Up",
      active:
        currentTrackingStatus === "Order-picked-up" ||
        currentTrackingStatus === "In-transit" ||
        currentTrackingStatus === "Out-for-pickup" ||
        currentTrackingStatus === "At-destination-hub",
      text: "Order picked up by delivery partner",
    },

    {
      status: "Out for Delivery",
      active: currentTrackingStatus === "Out-for-delivery",
      text: "Out for delivery",
    },
    {
      status: "At-delivery",
      active: currentTrackingStatus === "At-delivery",
      text: "The agent has arrived at the delivery location.",
    },
    ...(currentTrackingStatus === "Delivery-failed"
      ? [
          {
            status: "Delivery Failed",
            active: true,
            text: "Delivery failed",
          },
        ]
      : []),
    {
      status: "Delivered",
      active: currentTrackingStatus === "Order-delivered",
      text: "Order delivered",
    },
  ];
  const trackingDetailsArr =
    cart?.initResponse?.context?.domain == "ONDC:RET11" ? fnbtrackingDetailsArr : notrackingDetailsArr;

  // const trackingDetailsArr = [
  //   {
  //     status: "Pending",
  //     active: currentTrackingStatus === "Pending",
  //     text: "Pending confirmation",
  //   },
  //   {
  //     status: "Packed",
  //     active: currentTrackingStatus === "Packed",
  //     text: "Order packed",
  //   },
  //   {
  //     status: "Assigned",
  //     active: currentTrackingStatus === "Agent-assigned",
  //     text: "Agent assigned to order",
  //   },
  //   {
  //     status: "Picked Up",
  //     active: currentTrackingStatus === "Order-picked-up",
  //     text: "Order picked up",
  //   },
  //   {
  //     status: "On the way",
  //     active: currentTrackingStatus === "Out-for-delivery",
  //     text: "Order out for delivery",
  //   },
  //   ...(currentTrackingStatus === "Delivery-failed"
  //     ? [
  //         {
  //           status: "Delivery Failed",
  //           active: true,
  //           text: "Order not delivered",
  //         },
  //       ]
  //     : [
  //         {
  //           status: "Delivered",
  //           active: currentTrackingStatus === "Order-delivered",
  //           text: "Order delivered",
  //         },
  //       ]),
  // ];

  const cancelledDetailsArr = [
    {
      status: "Cancelled",
      active: currentTrackingStatus === "Cancelled",
      text: "Order Cancelled",
    },
  ];
  const failedDetailsArr = [
    {
      status: "Failed",
      active: currentTrackingStatus === "Failed",
      text: "Order Failed",
    },
  ];

  // Add return-related statuses directly
  const returnStatuses = [
    {
      status: "Return Requested",
      active: returnStatus === "Return_Requested",
      text: "Return requested",
    },
    {
      status: "Return Initiated",
      active: returnStatus === "Return_Initiated",
      text: "Return initiated",
    },
    {
      status: "Return Approved",
      active: returnStatus === "Return_Approved",
      text: "Return approved",
    },
    {
      status: "Return Picked",
      active: returnStatus === "Return_Picked",
      text: "Return picked",
    },
    {
      status: "Return Delivered",
      active: returnStatus === "Return_Delivered",
      text: "Return delivered",
    },
  ];

  const transactionID =
    parentOrder?.payments?.acquirerData?.upiTransactionId ||
    parentOrder?.payments?.acquirerData?.bankTransactionId ||
    parentOrder?.payments?.acquirerData?.transactionId;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const productsListingHandler = () => {
    let newCart: any = cart?.items;
    let newReturns: any = returns;
    let products: any = [];
    newCart.forEach((cartItem: any) => {
      newReturns.forEach((returnItem: any) => {
        if (cartItem?.id === returnItem?.itemId) {
          products.push({ ...cartItem, ...returnItem });
        } else {
          products.push(cartItem);
        }
      });
    });

    setProductsListing(products);
  };

  useEffect(() => {
    // if (returns?.length) {
    //   productsListingHandler()
    // } else {
    setProductsListing(cart?.items);
    // }
  }, [cart, returns]);

  return (
    <div className="pg-container ">
      <Header />
      <main className="pg-main bg-white flex-1" style={{ height: "calc(100% - 66px)" }}>
        <div className="orderDetails">
          <div className="mb-30 txt-30 txtw-6">{cart?.providerName}</div>

          <div className="detailsBanner">
            <p>{customerId?.name}</p>
            <p>Order ID: {customerOrderId || "-"}</p>
            <p>
              Time Placed:{" "}
              {createdAt ? (
                <>
                  {getDisplayDate(createdAt)}, {getDisplayTime(createdAt)}
                </>
              ) : null}
            </p>
            <p>
              Time Received:{" "}
              {updatedAt ? (
                <>
                  {getDisplayDate(updatedAt)}, {getDisplayTime(updatedAt)}
                </>
              ) : null}
            </p>
          </div>

          <div className="detailsCardContainer">
            <div className="detailsCard">
              <p className="description">Total Products</p>
              <p className="subHeading">{cart?.items?.length}</p>
            </div>
            <div className="detailsCard">
              <p className="description">Total Amount</p>
              <p className="subHeading">
                <span>{settlement?.amount?.currency}&nbsp;</span>
                {settlement?.amount?.value.toFixed(2)}
              </p>
            </div>
            <div className="detailsCard">
              <p className="description">Paid By</p>
              <p className="subHeading txt-ucase">{settlement?.settlementInfo?.settlementType}</p>
            </div>
            <div className="detailsCard">
              <p className="description">Order Status</p>
              <p className="subHeading">{getDisplayStatus(fulfillment?.status, status)}</p>
            </div>
          </div>

          <div className="productsDetailsContainer">
            <div className="detailsDescription mb-15 mt-40">Products</div>

            {productsListing?.length && (
              <CustomTable columns={ecommerceColumns} data={productsListing} className="productsTable mb-30" />
            )}
          </div>

          <div className="productDetailsCardContainer mb-30">
            <DetailsCard detailsType="Payment Details" isInnerCard={true}>
              <div className="flex justify-stretch">
                <div>
                  <Visa />
                </div>
                <div>
                  <p className="txt-ucase">{settlement?.settlementInfo?.settlementType || "-"}</p>
                  <p className="txt-ucase">{settlement?.settlementInfo?.bankAccountNo || "-"}</p>
                  <p className="txt-ucase">Transaction ID: {transactionID || "-"}</p>
                  <p className="txt-ucase">
                    Time Done: &nbsp;
                    {parentOrder?.payments ? getDisplayDate(parentOrder?.payments?.createdAt) : "-"}
                  </p>
                </div>
                <div>
                  <PhonePe />
                </div>
              </div>
            </DetailsCard>

            <DetailsCard
              // isTag={true}
              detailsType="Delivery Address"
              isInnerCard={true}
              // status={fulfillment?.status}
            >
              <div>{parentOrder?.addresses?.delivery?.name}</div>
              <div>{parentOrder?.addresses?.delivery?.building}</div>
              <div>{parentOrder?.addresses?.delivery?.locality}</div>
              <div>{parentOrder?.addresses?.delivery?.city}</div>
              <div>
                {parentOrder?.addresses?.delivery?.state} - {parentOrder?.addresses?.delivery?.areaCode}
              </div>
            </DetailsCard>

            <DetailsCard
              isButton={!cart?.providerContact?.phone && true}
              detailsType="Seller details"
              isInnerCard={true}
              buttonText="Contact Seller">
              <div>{cart?.providerName}</div>
              <div>{cart?.providerAddress?.street}</div>
              <div>{cart?.providerAddress?.city?.code}</div>
              <div>
                {cart?.providerAddress?.state?.code || cart?.providerAddress?.state} - {cart?.providerAddress?.areaCode}
              </div>
              <div>Email:{cart?.providerContact?.email} </div>
              <div>Phone: {cart?.providerContact?.phone}</div>
            </DetailsCard>

            {returns?.length ? (
              <>
                <DetailsCard
                  // onClickButton={window.open("tel:900300400")}
                  detailsType="Return details"
                  // isButton={true}
                  // buttonText="Contact tracking partner"
                >
                  <div>
                    <ul>
                      {returns?.map((item: any) => {
                        return (
                          <>
                            <p>Item Name: {item?.itemName}</p>
                            <li>
                              <p>
                                <b>{item?.status}:</b>&nbsp;
                                {item?.returnReason?.reason}
                              </p>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </DetailsCard>
              </>
            ) : (
              ""
            )}

            {refundDetails && (
              <DetailsCard detailsType="Refund details" isInnerCard={false}>
                <div>
                  {refundDetails?.map((refund: any, idx: number) => (
                    <Fragment key={idx}>
                      <div className="flex justify-stretch ">
                        <p>{refund.refundType}</p> <p>{refund.refundedAmount}</p>
                      </div>
                      <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                    </Fragment>
                  ))}
                  <div className="flex justify-stretch txt-18 txtw-6 ">
                    <p>Total Amount</p>{" "}
                    <p>{refundDetails.reduce((acc: number, cur: any) => cur.refundedAmount + acc, 0)}</p>
                  </div>
                </div>
              </DetailsCard>
            )}

            <DetailsCard detailsType="Billing details" isInnerCard={false}>
              <div>
                {cart?.items?.map((item: any) => (
                  <Fragment key={item.name}>
                    <div className="flex justify-stretch ">
                      <p>{item.name}</p> <p>{item.price.value}</p>
                    </div>
                    <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                  </Fragment>
                ))}
                <Fragment>
                  <div className="flex justify-stretch ">
                    <p>Delivery</p> <p>{cart?.additions?.delivery?.value || "N/A"}</p>
                  </div>
                  <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                </Fragment>
                <Fragment>
                  <div className="flex justify-stretch ">
                    <p>Miscellaneous</p> <p>{cart?.additions?.misc?.value || "N/A"}</p>
                  </div>
                  <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                </Fragment>
                <Fragment>
                  <div className="flex justify-stretch ">
                    <p>Packaging</p> <p>{cart?.additions?.packing?.value || "N/A"}</p>
                  </div>
                  <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                </Fragment>
                <Fragment>
                  <div className="flex justify-stretch ">
                    <p>Tax</p> <p>{cart?.additions?.tax?.value || "N/A"}</p>
                  </div>
                  <Divider className=" pb-2 mt-10 mb-10" color="#D0D5DD" />
                </Fragment>
                <div className="flex justify-stretch txt-18 txtw-6 ">
                  <p>Total Amount</p> <p>{cart?.price?.value}</p>
                </div>
              </div>
            </DetailsCard>

            <DetailsCard
              // onClickButton={window.open("tel:900300400")}
              detailsType="Tracking details"
              // isButton={true}
              buttonText="Contact tracking partner">
              {status !== "Completed" && status !== "Cancelled" && status !== "Failed" && (
                <div style={{ color: "#6d7175" }}>
                  Estimated Delivery - {formatDate(fulfillment?.estimatedDelivery)}
                </div>
              )}
              {status === "Completed" && (
                <div style={{ color: "#6d7175" }}>Delivered on: - {formatDate(updatedAt)}</div>
              )}
              {fulfillment?.status === "Cancelled" || fulfillment?.status === "Cancel-pending" ? (
                <>
                  <>
                    {cancelledDetailsArr.findIndex(detail => detail.active) ? (
                      <Timeline
                        active={cancelledDetailsArr.findIndex(detail => detail.active) - 1}
                        bulletSize={24}
                        lineWidth={2}>
                        <Timeline.Item title={fulfillment?.status} key={fulfillment?.status} tt="capitalize">
                          <Text size="xs" mt={4}>
                            {fulfillment?.status === "Cancelled" ? "Order Cancelled" : "Order Cancellation Pending"}
                          </Text>
                        </Timeline.Item>
                      </Timeline>
                    ) : null}
                  </>
                  <>
                    {trackingDetailsArr.findIndex(detail => detail.active) ? (
                      <Timeline
                        active={trackingDetailsArr.findIndex(detail => detail.active) - 1}
                        bulletSize={24}
                        lineWidth={2}>
                        <Timeline.Item
                          title={fulfillment?.status}
                          bullet={<CheckCircle />}
                          key={fulfillment?.status}
                          tt="capitalize">
                          <Text size="xs" mt={4}>
                            {fulfillment?.status === "Cancelled" ? "Order Cancelled" : "Order Cancellation Pending"}
                          </Text>
                        </Timeline.Item>
                      </Timeline>
                    ) : null}
                  </>
                </>
              ) : status === "Failed" || fulfillment?.status == "Failed" ? (
                <div>
                  {failedDetailsArr.findIndex(detail => detail.active) ? (
                    <Timeline
                      active={failedDetailsArr.findIndex(detail => detail.active)}
                      bulletSize={24}
                      lineWidth={2}
                      sx={{
                        ".mantine-Timeline-itemBullet[data-active]": {
                          background: "#F0792E",
                        },
                        ".mantine-Timeline-itemBullet[data-active][data-with-child] ": {
                          border: "1px solid transparent",
                          background: "#FFF",
                        },
                      }}>
                      {failedDetailsArr?.map(detail => (
                        <Timeline.Item
                          title={detail.status}
                          bullet={detail.status == "Delivered" && detail.active && <CheckCircle />}
                          key={detail.status}>
                          <Text size="xs" mt={4}>
                            {detail.text}
                          </Text>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  ) : null}
                </div>
              ) : (
                <div>
                  {trackingDetailsArr.findIndex(detail => detail.active) ? (
                    <Timeline
                      active={trackingDetailsArr.findIndex(detail => detail.active)}
                      bulletSize={24}
                      lineWidth={2}
                      sx={{
                        ".mantine-Timeline-itemBullet[data-active]": {
                          background: "#F0792E",
                        },
                        ".mantine-Timeline-itemBullet[data-active][data-with-child] ": {
                          border: "1px solid transparent",
                          background: "#FFF",
                        },
                      }}>
                      {trackingDetailsArr?.map(detail => (
                        <Timeline.Item
                          title={detail.status}
                          bullet={detail.status == "Delivered" && detail.active && <CheckCircle />}
                          key={detail.status}>
                          <Text size="xs" mt={4}>
                            {detail.text}
                          </Text>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  ) : null}
                </div>
              )}
            </DetailsCard>
            {(fulfillment?.agentDetails || fulfillment?.authorization?.type === "OTP") && (
              <DetailsCard detailsType="Agent Details" isInnerCard={true}>
                <div>Name: {fulfillment?.agentDetails?.name ?? "-"}</div>
                <div>Phone: {fulfillment?.agentDetails?.phone ?? "-"}</div>
                {fulfillment?.authorization?.type === "OTP" && (
                  <div>OTP: {fulfillment?.authorization?.token ?? "-"}</div>
                )}
              </DetailsCard>
            )}

            {returns?.length ? (
              <DetailsCard detailsType="Return details">
                <div>
                  {returnStatuses.findIndex(detail => detail.active) ? (
                    <Timeline
                      active={trackingDetailsArr.findIndex(detail => detail.active)}
                      bulletSize={24}
                      lineWidth={2}>
                      {returnStatuses?.map(detail => (
                        <Timeline.Item title={detail.status} bullet={<CheckCircle />} key={detail.status}>
                          <Text size="xs" mt={4}>
                            {detail.text}
                          </Text>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  ) : null}
                </div>
              </DetailsCard>
            ) : (
              ""
            )}
          </div>

          {supportTickets?.length > 0 && <SupportTable supportTicketsData={supportTickets} />}
        </div>
      </main>
    </div>
  );
}
