import {
  useState,
  useEffect,
  ChangeEvent,
  MouseEvent,
  useCallback,
} from "react";
import { useUpdateTokens } from "utils/hooks";
import { currentRoleIsUser } from "utils/helper";
import { Header } from "components/Common/Header";
import { Button } from "components/Common/Button";
import { SearchBarContainer } from "components/Common/SearchBarContainer";
import { DeleteModal } from "components/Common/DeleteModal";
import AddPagesModal from "components/Pages/AddPages/AddPagesModal";
import { PaginationContainer } from "components/Common/PaginationContainer";
import { EmptyPage } from "components/Common/EmptyPage";
import {
  NoOffersSVG,
  PencilIconSVG,
  TrashboxIconSVG,
} from "assets/icons/index";
import CustomTable from "components/CustomTable/CustomTable";
import { pageType } from "types/offer";
import { createColumnHelper } from "@tanstack/table-core";
import { ActionIcon, Divider, Switch } from "@mantine/core";
import styles from "../Offers/Offers.module.scss";
import { getStorageItem } from "services/storage";
import { useNotification } from "context";
import {
  useDeletePageMutation,
  useGetPageListQuery,
  useUpdatePageMutation,
} from "hooks/Admin/pageAPI";
import { getPageList } from "services/apis/admin";

type PagesAPIOptionsType = {
  page: number;
  size: string;
};

const Pages = () => {
  // const [pageItems, setPageItems] = useState<any>([]);
  const [isActive, setIsActive] = useState(true);
  const [pagesAPIOptions, setPagesAPIOptions] = useState<PagesAPIOptionsType>({
    page: 1,
    size: "10",
  });

  const {
    data: pageItems,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    refetch,
  } = useGetPageListQuery(
    {
      page: pagesAPIOptions?.page,
      size: pagesAPIOptions?.size,
    },
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true, // Ensures the data is always refetched
    }
  );


  useEffect(() => {

    if (isLoading || isFetching) {
      console.log("Loading or Fetching Data...");
    }
    if (isSuccess || pageItems) {
    }
    if (isError) {
      refetch();
      console.log("Error occurred while fetching data");
    }
  }, [isLoading, isFetching, isSuccess, pageItems, isError, refetch]); // Logging based on the fetch status

  const { setText } = useNotification();
  const [isAddPageModalVisible, setAddPageModalVisible] = useState(false);
  const [isRemoveOfferModalVisible, setRemoveOfferModalVisible] =
    useState(false);
  const [editPageModal, setEditPageModal] = useState({
    isOpen: false,
    page: "",
  });
  const [toBeDeletedOffer, setToBeDeletedOffer] = useState("");
  const [offerVisible, setOfferVisible] = useState(true);
  useUpdateTokens();

  const onPageChange = (value: number) =>
    setPagesAPIOptions((prev) => ({ ...prev, page: value }));
  const onRecordsSizeChange = (value: string) =>
    setPagesAPIOptions((prev) => ({ ...prev, size: value }));

  const checkAdmin = () => {
    if (
      getStorageItem("role") === "PROTEAN_SUPER_ADMIN" ||
      getStorageItem("role") === "BUYER_APP_SUPER_ADMIN" ||
      getStorageItem("role") === "BUYER_APP_ADMIN"
    ) {
      setOfferVisible(true);
    } else {
      setOfferVisible(false);
    }
  };
  useEffect(() => {
    checkAdmin();
  }, []);

  useEffect(() => {
    if (toBeDeletedOffer.length > 0) {
      setRemoveOfferModalVisible(true);
    }
  }, [toBeDeletedOffer]);
  const [
    deletePage, // This is the mutation trigger
  ] = useDeletePageMutation();

  const [
    updatePage, // This is the mutation trigger
  ] = useUpdatePageMutation();

  async function handleRemoveOffer() {
    const response = await deletePage(toBeDeletedOffer);
    if (response.data.success) {
      setRemoveOfferModalVisible(false);
      setText("Page Removed");
    }
  }

  const onChangeStatus =
    (data: any, status: any, action: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {

      let payload = data;
      if (action === "active") {
        payload = {
          ...payload,
          isActive: !status,
        };
      } else if (action === "visible") {
        payload = {
          ...payload,
          isVisible: !status,
        };
      }
      delete payload._id;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.__v;
      updatePage({ id: data._id, payload });
    };

  const onDeletePage = (_id: string) => (event: MouseEvent<HTMLElement>) => {
    setToBeDeletedOffer(_id);
  };

  const columnHelper = createColumnHelper<pageType>();

  const columns = [
    columnHelper.accessor("_id", {
      header: "Page ID",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.ellipsis}>{info.getValue()}</div>
      ),
    }),

    columnHelper.accessor("title", {
      header: "Title",
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    }),

    columnHelper.accessor("isActive", {
      header: "Active",
      enableSorting: false,
      cell: (info: any) => {

        return (
          <Switch
            disabled={!offerVisible}
            defaultChecked={info.getValue()}
            onChange={onChangeStatus(
              info.row.original,
              info.getValue(),
              "active"
            )}
            color="primary"
            classNames={{ track: "cursor-ptr" }}
          />
        );
      },
    }),
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.action} style={{ display: "flex" }}>
          <ActionIcon
            onClick={() => {
              setEditPageModal({
                isOpen: true,
                page: info.row.original,
              });
            }}
          >
            <PencilIconSVG />
          </ActionIcon>
          <ActionIcon onClick={onDeletePage(info.row.original._id)}>
            <TrashboxIconSVG />
          </ActionIcon>
        </div>
      ),
      size: 80,
    },
  ];

  const columnVisibility = {
    action: !currentRoleIsUser(),
  };

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Pages</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Page", "Pages"]}
            itemsCount={pageItems?.count}
            listingCount={pageItems?.pages?.length}
          />
          {offerVisible ? (
            <Button
              type="filled-primary"
              text="Add Pages"
              onClick={() => setAddPageModalVisible(true)}
              classNames={{
                root: "add-admin-btn brd-10",
                label: "txt-14 txtw-5",
              }}
            />
          ) : null}
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {!!pageItems?.pages?.length ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80"  style={{ overflowX: "scroll" }}>
              <CustomTable
                columns={columns}
                data={pageItems?.pages}
                className={styles.table}
                columnVisibility={columnVisibility}
              />
            </section>
            <PaginationContainer
              page={pagesAPIOptions.page}
              setPage={onPageChange}
              size={pagesAPIOptions.size}
              setSize={onRecordsSizeChange}
              totalEntries={pageItems?.count}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Pages" />
        )}
      </main>

      <AddPagesModal
        editPageModal={editPageModal}
        isAddPageModalVisible={isAddPageModalVisible}
        setAddPageModalVisible={setAddPageModalVisible}
        setEditPageModal={setEditPageModal}
      />
      <DeleteModal
        isDeleteModalOpen={isRemoveOfferModalVisible}
        closeDeleteModal={() => {
          setRemoveOfferModalVisible(false);
          setToBeDeletedOffer("");
        }}
        data={{
          text: "Page",
        }}
        onConfirm={handleRemoveOffer}
      />
    </div>
  );
};

export default Pages;
