import { useEffect, useState } from "react";
import { Input, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "components/Common/Button";
import PhoneInputField from "components/Common/PhoneInput/PhoneInput";
import { DownArrowSVG } from "assets/icons";
import {
  useAddAdminMutation,
  useUpdateAdminMutation,
} from "hooks/Admin/adminAPI";

const BasicDetails = ({
  setAddAdminModalVisible,
  editAdminModal,
  setEditAdminModal,
}: {
  setAddAdminModalVisible: Function;
  editAdminModal: any;
  setEditAdminModal: Function;
}) => {
  const [phone, setPhone] = useState("");
  const [phoneValidationText, setPhoneValidationText] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [adminRoleValidationText, setAdminRoleValidationText] = useState("");

  const basicDetailsForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validate: {
      firstName: (value) =>
        value?.length === 0
          ? "First name is required"
          : /^[a-zA-Z]*$/.test(value)
          ? null
          : "Invalid name",
      lastName: (value) =>
        value?.length === 0
          ? "Last name is required"
          : /^[a-zA-Z]*$/.test(value)
          ? null
          : "Invalid name",
      email: (value) =>
        value?.length === 0
          ? "Email is required"
          : /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? null
          : "Invalid email",
    },
  });

  const [
    updateAdmin, // This is the mutation trigger
  ] = useUpdateAdminMutation();

  const [
    addAdmin, // This is the mutation trigger
  ] = useAddAdminMutation();

  useEffect(() => {
    if (editAdminModal.isOpen) {
      basicDetailsForm.setValues({
        firstName: editAdminModal.data.firstName,
        lastName: editAdminModal.data.lastName,
        email: editAdminModal.data.email,
      });
      setPhone(editAdminModal.data.phone);
      if (editAdminModal.data.role === "BUYER_APP_ADMIN") {
        setAdminRole("Buyer Admin");
      } else if (editAdminModal.data.role === "PROTEAN_SUPER_ADMIN") {
        setAdminRole("Protean Super Admin");
      } else if (editAdminModal.data.role === "BUYER_APP_SUPER_ADMIN") {
        setAdminRole("Buyer Super Admin");
      } else {
        setAdminRole("User");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAdminModal]);

  async function handleAddAdmin() {
    const payload = {
      firstName: basicDetailsForm.values.firstName,
      lastName: basicDetailsForm.values.lastName,
      email: basicDetailsForm.values.email,
      phone: phone,
      role: adminRole === "Buyer Admin" ? "BUYER_APP_ADMIN" : "USER",
    };
    const response = await addAdmin(payload);
    if (response?.data?.success) {
      setAddAdminModalVisible(false);
    }
  }

  async function handleEditAdmin() {
    const id = editAdminModal.adminId;
    const payload = {
      firstName: basicDetailsForm.values.firstName,
      lastName: basicDetailsForm.values.lastName,
      email: basicDetailsForm.values.email,
      phone: phone,
      role: adminRole === "Buyer Admin" ? "BUYER_APP_ADMIN" : "USER",
      // img: {
      //   profilePic: adminPhoto,
      // },
    };
    const response = await updateAdmin({ id, payload });
    if (response.data.success) {
      setEditAdminModal({
        isOpen: false,
        adminId: "",
        data: {},
      });
    }
  }

  function handleBasicDetailsSubmit(values: typeof basicDetailsForm.values) {
    let isValid = true;
    // Validate Authority
    if (!adminRole) {
      setAdminRoleValidationText("Authority is required");
      isValid = false;
    } else {
      setAdminRoleValidationText("");
    }
    // Validate Phone
    handlePhoneValidation("PHONE", phone);
    if (phoneValidationText !== "") {
      isValid = false;
    }
    // If all validations pass, proceed
    if (isValid) {
      if (editAdminModal.isOpen) {
        handleEditAdmin();
      } else {
        handleAddAdmin();
      }
    }
  }

  function handleBasicDetailsError(error: typeof basicDetailsForm.errors) {
    handlePhoneValidation("PHONE", phone);
    // Trigger validation error for Authority
    if (!adminRole) {
      setAdminRoleValidationText("Authority is required");
    }
  }

  function handlePhoneValidation(type: string, val: string) {
    if (val?.length === 0) {
      setPhoneValidationText("Phone required");
    } else if (val?.length < 11) {
      setPhoneValidationText("Invalid phone");
    } else {
      setPhoneValidationText("");
    }
    setPhone(val);
  }

  return (
    <form
      onSubmit={basicDetailsForm.onSubmit(
        handleBasicDetailsSubmit,
        handleBasicDetailsError
      )}
    >
      <p className="txt-16 mb-20">Basic Details</p>
      <div className="grid grid-cols-2 col-gap-40 row-gap-25">
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="FIRST NAME"
          required
        >
          <TextInput
            classNames={{
              wrapper: "clr-grey txt-12 txtw-4",
            }}
            placeholder="Enter First Name"
            variant="filled"
            {...basicDetailsForm.getInputProps("firstName")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="LAST NAME"
          required
        >
          <TextInput
            placeholder="Enter Last Name"
            variant="filled"
            {...basicDetailsForm.getInputProps("lastName")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="EMAIL"
          required
        >
          <TextInput
            placeholder="Enter Email"
            variant="filled"
            {...basicDetailsForm.getInputProps("email")}
          />
        </Input.Wrapper>

        <PhoneInputField
          label="PHONE"
          phone={phone}
          validationText={phoneValidationText}
          handlePhoneValidation={handlePhoneValidation}
        />
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="AUTHORITY"
          required
          error={adminRoleValidationText}
        >
          <Select
            variant="filled"
            value={adminRole}
            onChange={(val: string) => {
              setAdminRole(val);
              if (val) setAdminRoleValidationText("");
            }}
            data={["Buyer Admin", "User"]}
            rightSection={<DownArrowSVG />}
            disabled={
              editAdminModal?.data?.role === "PROTEAN_SUPER_ADMIN" ||
              editAdminModal?.data?.role === "BUYER_APP_SUPER_ADMIN" ||
              editAdminModal?.data?.role === "BUYER_APP_ADMIN" 
            }
          />
        </Input.Wrapper>
      </div>
      <div className="flex justify-end bd-top mt-10 pt-10">
        <Button
          type="filled-primary"
          text={`${editAdminModal.isOpen ? "Save" : "Add"}`}
          onClick={() => {
            basicDetailsForm.onSubmit(
              handleBasicDetailsSubmit,
              handleBasicDetailsError
            );
          }}
        />
      </div>
    </form>
  );
};

export default BasicDetails;
