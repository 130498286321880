import React, { ReactNode } from "react";
import "./DetailsCard.scss";
import { Button } from "components/Common/Button";
import RoleStatusBadge from "components/Common/Badge/RoleStatus";

interface propTypes {
  width?: string;
  height?: string;
  detailsType: string;
  isInnerCard?: boolean | undefined;
  isButton?: boolean | undefined;
  isTag?: boolean | undefined;
  children?: ReactNode;
  buttonText?: string;
  status?: string;
  onClickButton?: () => void;
}

const DetailsCard = ({
  children,
  detailsType,
  isInnerCard,
  isTag,
  isButton,
  buttonText,
  height,
  onClickButton,
  width,
  status = "",
}: propTypes) => {
  return (
    <div
      className="detailsContainer"
      style={{
        width: width || "",
        alignSelf: width ? "center" : "",
        height: height || "",
      }}>
      <div className="heading">
        <div className="txt-18 txtw-7">{detailsType}</div>

        {isButton && (
          <div className="btnContainer">
            <Button type="filled-primary" text={buttonText || ""} onClick={onClickButton} />
          </div>
        )}
        {isTag && status && (
          <div className="txt-14 txtw-5">
            <RoleStatusBadge type={status}>
              <span className="marker marker-green" />
            </RoleStatusBadge>
          </div>
        )}
      </div>

      <div className={`${!!isInnerCard ? "innerCard p-3" : "w-full p-3"} `}>{children}</div>
    </div>
  );
};

export default DetailsCard;
