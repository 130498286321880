import { useEffect, useState } from "react";
import { Input, Radio, Select, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import ReactQuill from "react-quill";
import { Dropzone } from "components/Common/Dropzone";
import { Button } from "components/Common/Button";
import { getCategories } from "services/apis/admin";
import { getOfferDetails } from "services/apis/admin/banners";
import "react-quill/dist/quill.snow.css";
import {
  useAddBannerMutation,
  useUpdateBannerMutation,
} from "hooks/Admin/bannersAPI";
import { getStorageItem } from "services/storage";
import { useNotification } from "context";
import { DownArrowSVG } from "assets/icons";
import { useGetSubCategoryListQuery } from "hooks/Admin/subCategoryAPI";
import { checkAuth } from "utils/helper";
import { useNavigate } from "react-router-dom";

const adminType = getStorageItem("adminType");

const BasicDetails = ({
  setAddBannerModalVisible,
  setEditOfferModal,
  editOfferModal,
}: {
  setAddBannerModalVisible: Function;
  setEditOfferModal: Function;
  editOfferModal: any;
}) => {
  const [offerLogo, setOfferLogo] = useState<any>("");
  const [allCategories, setAllCategories] = useState([]);
  const [offerCoverValidationText, setOfferCoverValidationText] = useState("");
  const offerDetailsForm = useForm<any>({
    validateInputOnChange: true,
    initialValues: {
      title: "",
      description: "",
      isActive: false,
      isVisible: false,
      category: "",
      subCategory: "",
      offerDetails: {
        offerLevel: "CATEGORY",
      },
    },

    validate: {
      title: (value) => {
        if (value?.length === 0) {
          return "Title is required";
        } else if (value.length > 30) {
          return "Title must be 30 characters or less";
        }
        return null;
      },
      description: (value) =>
        value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
        !value.includes("<img")
          ? "Description is required"
          : null,
      offerDetails: {
        offerLevel: (value) =>
          value?.length === 0 ? "Offer level is required" : null,
      },
      category: (value, values) => {
        return values.offerLevel === "CATEGORY" &&
          (!value || String(value)?.length === 0)
          ? "Category is required"
          : null;
      },
    },
  });

  const { setText } = useNotification();

  const [updateOffer] = useUpdateBannerMutation();
  const [addOffer] = useAddBannerMutation();
  const navigate = useNavigate();
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const { data: subCategoryList } = useGetSubCategoryListQuery({
    categoryId: selectedCategoryId,
  });
  const subCategoryListOptions =
    subCategoryList?.data?.map((subCat: any) => ({
      value: subCat._id,
      label: subCat.subCategoryName,
    })) || [];

  const fetchOfferDetails = async (id: any) => {
    const response = await getOfferDetails(id);
    if (response.success) {
      setOfferLogo(response.data.img);
      offerDetailsForm.setValues({
        title: response.data.title,
        couponCode: response.data.couponCode,
        description: response.data.description,
        category: response?.data?.category,
        subCategory: response?.data?.subCategory,
        isVisible: response.data.isVisible,
      });
    }
  };

  useEffect(() => {
    if (editOfferModal.isOpen) {
      fetchOfferDetails(editOfferModal?.offer?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOfferModal.isOpen]);

  useEffect(() => {
    if (editOfferModal?.isOpen && offerDetailsForm?.values) {
      setSelectedCategoryId(offerDetailsForm.values.category);
    }
  }, [editOfferModal?.isOpen, offerDetailsForm.values]);

  useEffect(() => {
    (async () => {
      checkAuth(
        async () => {
          const response = await getCategories(1, "50");
          if (response) {
            setAllCategories(
              response.data.data.map((category: any) => ({
                value: category._id,
                label: category.categoryName,
              }))
            );
          }
        },
        setText,
        navigate
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleAddOffer() {
    if (!offerLogo) {
      setOfferCoverValidationText("Image is required");
      return;
    }

    let payload = {
      img: offerLogo._id,
      title: offerDetailsForm.values.title || undefined,
      description: offerDetailsForm.values.description || undefined,
      isVisible: offerDetailsForm.values.isVisible,
      category:
        offerDetailsForm.values.offerDetails.offerLevel === "CATEGORY"
          ? offerDetailsForm.values.category
          : undefined,
      subCategory:
        offerDetailsForm.values.offerDetails.offerLevel === "CATEGORY"
          ? offerDetailsForm.values.subCategory
          : undefined,

      isBusiness: adminType === "e-commerce-b2b" ? true : false,
    };
    if (!payload.category) {
      delete payload.category;
    }

    if (!payload.subCategory) {
      delete payload.subCategory;
    }

    const id = editOfferModal?.offer?._id;
    if (editOfferModal.isOpen) {
      const response = await updateOffer({ id, payload });
      if (response?.data?.success) {
        setEditOfferModal({
          isOpen: false,
          offer: "",
        });
        setText("Banner Updated");
      }
    } else {
      const response = await addOffer(payload);
      if (response.data.success) {
        setAddBannerModalVisible(false);
        setText("Banner Added");
      }
    }
  }

  return (
    <form
      className="w-650 pt-20 pb-20 pl-20 pr-20"
      onSubmit={offerDetailsForm.onSubmit(handleAddOffer)}
    >
      <p className="txt-16 mb-20">Banner Details</p>
      <Dropzone
        mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
        media={offerLogo?.link}
        setMedia={(img) => {
          setOfferLogo(img[0]);
        }}
        mediaSize={2000000}
        mediaValidationText={offerCoverValidationText}
        setMediaValidationText={setOfferCoverValidationText}
      />
      <div className="grid grid-cols-2 col-gap-40 row-gap-25 pb-10">
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="Banner TITLE"
          required
        >
          <TextInput
            placeholder="Enter Banner Title"
            variant="filled"
            {...offerDetailsForm.getInputProps("title")}
          />
        </Input.Wrapper>

        <div className="gcol-start-1 gcol-end-3">
          <p className="clr-grey txtw-4 txt-12 txt-ucase">description <span style={{ color: "red" }}>*</span></p>
          <ReactQuill
            theme="snow"
            {...offerDetailsForm.getInputProps("description")}
          />
          {offerDetailsForm.errors.description && (
            <p className="txt-12 clr-red mt-5">
              {offerDetailsForm.errors.description}
            </p>
          )}
        </div>

        <Radio.Group
          label="offer level"
          withAsterisk
          classNames={{
            root: "gcol-start-1 gcol-end-3",
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          {...offerDetailsForm.getInputProps("offerDetails.offerLevel")}
        >
          <Radio
            value="CATEGORY"
            label="Category Level"
            classNames={{
              label: "txt-12 txtw-4 clr-grey txt-cap",
            }}
          />
        </Radio.Group>

        <Select
          data={allCategories}
          label="category"
          placeholder="Pick the Category"
          classNames={{
            root: "gcol-start-1 gcol-end-3",
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          rightSection={<DownArrowSVG />}
          {...offerDetailsForm.getInputProps("category")}
          onChange={(e: any) => {
            offerDetailsForm.setFieldValue("category", e);
            setSelectedCategoryId(e);
          }}
        />

        <Select
          data={subCategoryListOptions}
          disabled={!subCategoryListOptions.length}
          label="sub category"
          placeholder="Pick a sub category"
          classNames={{
            root: "gcol-start-1 gcol-end-3",
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          rightSectionWidth={offerDetailsForm.values.subCategory ? 50 : 30}
          rightSection={
            offerDetailsForm.values.subCategory ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "10px",
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={(e) =>
                    offerDetailsForm.setFieldValue("subCategory", "")
                  }
                >
                  remove
                </span>
              </div>
            ) : (
              <DownArrowSVG />
            )
          }
          {...offerDetailsForm.getInputProps("subCategory")}
        />

        <div className="flex justify-stretch align-center gcol-start-1 gcol-end-2">
          <p className="txt-12 txtw-5 txt-ucase clr-grey">Banner visible</p>
          <Switch
            checked={offerDetailsForm.values.isVisible}
            color="primary"
            {...offerDetailsForm.getInputProps("isVisible", {
              type: "checkbox",
            })}
          />
        </div>
      </div>
      <div className="flex justify-end bd-top pt-10">
        <Button
          type="filled-primary"
          text={`${editOfferModal.isOpen ? "Save" : "Add"}`}
          loading={false}
          onClick={() => {}}
        />
      </div>
    </form>
  );
};

export default BasicDetails;
